.list-vat-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-vat-items * {
  cursor: default;
}
.list-vat-items .Polaris-Button {
  border: 1px solid transparent !important;
  background-color: transparent !important;
}
.list-vat-items .table-cell {
  max-width: 150px;
  height: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.list-vat-items .table-cell_button {
  width: 30px;
  height: 30px;
  background-color: transparent;
  margin: 0 3px;
  border: none;
}
.list-vat-items .table-cell_button * {
  cursor: pointer;
}
.list-vat-items .table-cell_button-icon {
  width: 100%;
  height: 100%;
}
.list-vat-items .table-bordered {
  border-collapse: collapse;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 48em) {
  .Polaris-Modal-Dialog__Modal {
    min-width: 98%;
    height: 100%;
  }
}
.transaction-create .Polaris-Page {
  padding-bottom: 20px;
}
.transaction-create_form-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.transaction-create_form-wrapper_fields {
  flex: 0 0 50%;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 30px;
}
.transaction-create_form-wrapper_fields-additional {
  padding-left: 20px;
  max-height: calc(65vh - 100px);
  overflow-y: auto;
}
.transaction-create_form-wrapper_fields-title {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 15px;
}
.transaction-create_form-wrapper .Polaris-Labelled__LabelWrapper {
  margin-top: 10px;
}

.yellowBgLine {
  background-color: rgba(255, 99, 71, 0.1) !important;
}
.yellowBgLine input {
  background-color: rgba(255, 99, 71, 0.1) !important;
}

/*# sourceMappingURL=customElement.css.map */
