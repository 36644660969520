$bg-primary: #fff;

.Datepicker {
  position: relative;
  &-Wrapper {
    top: 70px;
    position: absolute;
    background: $bg-primary;
    padding: 18px;
    box-shadow: 0px 8px 32px rgba(91, 112, 133, 0.35);
    z-index: 200;
    .react-datepicker {
      border: none;
      &__header {
        background-color: transparent;
        border: none;
      }
      &__day,
      &__day-name {
        width: 40px;
        line-height: 40px;
        border-radius: var(--p-border-radius-05) !important;
      }
      &__day {
        &--in-range {
          color: #000;
          background-color: var(--p-background-pressed);
        }
        &--selected,
        &--keyboard-selected {
          color: #fff;
          background-color: var(--p-action-primary);
          font-weight: 700;
        }
        &--in-selecting-range {
          color: #000;
          background-color: var(--p-background-pressed) !important;
        }
        &--selecting-range-start,
        &--range-end {
          color: #fff;
          font-weight: 700;
          background-color: var(--p-action-primary) !important;
        }
        &:hover {
          color: #fff;
          font-weight: 700;
          background-color: var(--p-action-primary) !important;
        }
      }

      &__year-dropdown {
        background-color: $bg-primary;
        box-shadow: 0px 4px 8px rgba(91, 112, 133, 0.35);
        border: 0;
      }

      &__header__dropdown {
        padding: 8px;
        font-weight: 700;
      }

      &__year-option {
        line-height: 40px;
        font-size: 12px;
        font-size: 400;
        &:hover {
          color: #fff;
          background-color: var(--p-action-primary) !important;
        }
      }

      &__month {
        line-height: 40px;

        &--selected {
          color: #fff;
          background-color: var(--p-action-primary);
          &:hover {
            color: #fff;
            background-color: var(--p-action-primary);
          }
        }
      }
    }
  }
}
