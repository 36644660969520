.SaveBar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55.8%;
  height: 72px;
  position: fixed;
  bottom: 0;
  right: var(--p-space-6);
  background: #ffffff;
  box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px 10px 0px 0px;
  z-index: 100;
  transition: 0.5s;

  @media screen and (max-width: 1027px) {
    width: 72%;
  }

  @media (max-width: 48em) {
    left: var(--p-space-6);
    right: var(--p-space-6);
    width: auto;
  }
}

.SaveBar.fullWidth {
  position: absolute;
  width: calc(100% - 2 * var(--p-space-6));
}

.drawer-close + .content-container .SaveBar {
  padding: 10px;
  transition: 0.5s;
}
