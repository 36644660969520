.ReactModal__Overlay {
  .reactModal {
    opacity: 0;
    transform: translate(-50%, -80%);
    transition: all 100ms ease-in-out;
  }
}

.ReactModal__Overlay--after-open {
  .reactModal {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.ReactModal__Overlay--before-close {
  .reactModal {
    opacity: 0;
    transform: translate(-50%, -80%);
  }
}

.reactModalOverlay {
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  z-index: 300;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  top: 0px;
  left: 0px;
}

.reactModal {
  max-height: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  outline: none;
}

.modal {
  min-width: 440px;
  max-width: 680px;
  padding: 24px;
  &__header {
    position: relative;
  }
  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
  }
  &__title-centered {
    text-align: center;
  }
  &__content {
    // **
  }
  &__footer {
    margin-top: 32px;
  }
  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #707070;
    margin-top: 8px;
  }
  &__close {
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
  }
  &__infoIconWrap {
    width: 40px;
    height: 40px;
    margin-bottom: 12px;
  }
  &__infoIcon {
    width: 100%;
    height: 100%;
  }
}

.modal.contentOnCenter {
  .modal__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .modal__description {
    text-align: center;
  }
}
