.card {
  width: fit-content;
  padding: 1rem 2rem;
  border: 2px solid var(--p-interactive);
  border-radius: var(--p-border-radius-2);
}

.subscription-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}
