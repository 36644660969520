.messages {
  &__message {
    &__text {
      width: 80%;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &__unread-point {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: #007cd2;
    }
    &__file {
      width: 250px;
      padding: 10px 0px;
      display: flex;
      gap: 0.5rem;
      a {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
