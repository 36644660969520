body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  --p-action-primary: #0d6efd;
  --p-action-primary-disabled: rgba(241, 241, 241, 1);
  --p-action-primary-hovered: #0d6efd;
  --p-action-primary-pressed: #1e66ac;
  --p-action-primary-depressed: #18528a;
  --p-space-4: 0.85rem;
}

input {
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  &[data-autocompleted] {
    background-color: transparent !important;
  }
}

.Polaris-TextField__Spinner{
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rc-pagination {
  text-align: end;
}

.App {
  max-width: 2000px;
  margin: auto;
}

.bg-red {
  background-color: #fef3f2;
}

.bg-yellow {
  background-color: #fffbec;
}

.Polaris-Button {
  &.Polaris-Button--outline.Polaris-Button--iconOnly {
    border: none !important;
  }
}

.border-top {
  border-top: 1px solid #e1e3e5;
}
.Polaris-DataTable__Cell--verticalAlignTop {
  white-space: nowrap;
}

.Polaris-DataTable__Cell {
  > .ButtonOnHover {
    opacity: 0;
    transition: 0.1s ease;
  }

  &.Polaris-DataTable__Cell--hovered > .ButtonOnHover {
    opacity: 1;
  }
  .Polaris-Box{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
  }
}

.Polaris-Spinner svg {
  fill: #0d6efd;
}

.Polaris-ResourceItem:hover {
  background-color: rgba(0, 0, 0, 0.025);
}

.featureCounterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55%;
  margin: 0 auto;
}

.featureCounter {
  display: flex;
  gap: 4px;
  margin-left: auto;
  width: fit-content;
  padding: 2px;
  border: solid 1px #aaa;
  border-radius: 3px;
}

