.App {
  .Polaris-Navigation {
    background-color: #fff;
    border-right: none;
    box-shadow: 0 1.25rem 3.75rem rgba(182, 195, 211, .5);

    &__PrimaryNavigation {
      justify-content: space-between;
      padding-bottom: 56px;
      overflow: hidden auto;
    }

    &__Text {
      color: #667085;
      font-size: 12px;
    }

    &__Badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      color: #fff;
      background: #0d6efd;
      border-radius: 18px;
      font-weight: 700;
      font-size: 10px;
      margin-top: 5px;
      margin-right: 1rem;

      transition: 0.1s ease;
    }

    &__Icon {
      svg {
        fill: none !important;
        path: {
          fill: none !important;
        }
      }
    }

    &__Item {
      margin: 0;
      padding-top: 8px;
      padding-bottom: 8px;
      &--selected {
        color: #000;
        background-color: #bee1fc;
      }

      &:hover {
        background-color: #f5faff;
      }
      &--selected {
        background-color: #f5faff;
        &::before {
          right: 0;
          left: auto;
        }
      }
    }
  }
}

.nav-closed {
  .Polaris-Navigation {
    min-width: 0;
    width: 68px;

    &__Item {
      display: flex;
      justify-content: center;
    }
    &__Badge {
      display: none;
    }
    &__Text {
      display: none;
    }
  }
}

.main-logo {
  padding-bottom: 90px;
}

.main-logo.closed {
  padding-left: 14px !important;
}

.profileNav {
  padding-bottom: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f5faff;
  }
  &.closed {
    svg {
      margin-left: 10px !important;
    }
  }
  &.selected {
    background-color: #f5faff;
  }
}

.companyNameSliced {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  white-space: nowrap;
}

@media (max-width: 48em) {
  /* hiding the mobile menu for the desktop */
  .main-logo {
    display: none;
  }
  .App .Polaris-Frame__Main {
    padding-left: 0px !important;
  }
}

@media (min-width: 48em) {
  /* hiding the mobile menu for the desktop */
  .main-logo {
    padding-left: 1rem;
  }

  .Polaris-Frame__TopBar {
    display: none;
  }
  .App .Polaris-Frame--hasTopBar .Polaris-Frame__Main {
    padding-top: 0;
  }
  .App .Polaris-Frame--hasTopBar .Polaris-Frame__Navigation {
    top: 0;
    height: 100%;
    z-index: 200;
    background-color: white;
  }
}
