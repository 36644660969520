$bg-primary: #fff;

.userProfile {
  position: relative;
  width: 100%;

  &-Wrapper {
    min-width: 240px;
    position: absolute;

    left: 156px;
    bottom: 20px;

    background: $bg-primary;
    border-radius: 8px;
    border: 1px solid var(--grey-50, #eee);
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
      0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  }

  &-header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px;
  }

  &-content {
    &-option {
      display: flex;
      gap: 12px;
      padding: 12px 16px;

      border-top: var(--p-border-width-1) solid var(--p-background);

      cursor: pointer;

      &:hover {
        background-color: #f5faff;
      }

      &:last-child {
        border-radius: 0px 0px 8px 8px;
      }
    }
  }
}
