.taxSettings {
  &-section {
    padding-top: 1rem;
  }
}

.error-checkbox-view {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 30px;
  height: 30px;

  .Polaris-Choice__Control {
    margin: 0 !important;
  }
}

.error-message {
  color: red;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}